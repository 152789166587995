/*!
*
* Template: Socialv - Responsive Bootstrap 5 Admin Dashboard Template
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for color variable.
*
*/


// Configuration
@import "./bootstrap/functions";
// Variables
@import "./custom/variables";
@import "./custom/variables/index";
@import "./bootstrap/variables";
@import "./bootstrap/mixins";

@import "./customizer/variables";
@import "./customizer/reboot";
@import "./customizer/root";

// Components
@import "./customizer/components";
@import "./customizer/dark";
@import "./customizer/layouts";
@import "./customizer/utilities/utilities";

// change the theme
$theme-colors: (
  "primary": #0074d9,
  "danger": #ff4136
);