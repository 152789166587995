.right-sidebar-mini {
    left: 0; right:unset;
    transform: translateX(calc(1% + -1em));
    
    .ri-arrow-right-line:before {
        content: "\ea60";
    }
    .ri-arrow-left-line:before {
        content: "\ea6c";
    }
}
.right-sidebar-toggle {
    @if $enable-rounded == true {
		@include radius-mixin(0px 30px 30px 0px);
	}
    margin-right: -2.5rem;
    margin-left: unset;
}
.right-sidebar-mini.right-sidebar {
    -webkit-transform: translateX(calc(-90% + -2em));
    transform: translateX(calc(-90% + -2em));
    &.right-sidebar {
        .right-sidebar-toggle {
            margin-right: -2.5rem;
            margin-left: unset;
        }
    }	
}
@media (min-width: 1200px){
    body.sidebar-main .iq-footer {
        margin-right: 5rem;
        width: calc(100vw - 20.5rem);
    }
}
@media (min-width:1500px) {	
    .right-sidebar-toggle {
        margin-right: 0;
        margin-left: unset;
    }
    .right-sidebar-mini {
		&:hover {
			.right-sidebar-toggle {
				margin-right: -2.5rem;
				margin-left: unset;
                @if $enable-transitions == true {
					@include transition-mixin(all 0.3s ease-in-out);
				}
			}
		}

	}
}
.btn-setting{
    &.btn-fixed-end{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
