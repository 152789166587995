
    .nav{
        background: $card-bg;
        
        &.nav-tabs {
            .nav-item {
                a{
                    color: $body-color;
                }
            }
        }
    }
.iq-burger-menu{
    color: $white;
}
.navbar-list {
    li {
        
        >a {
            border-bottom-color: $border-color;
        }
    }
}