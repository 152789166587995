// Override default variables before the import
// you can open the bootstrap
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

@import "../node_modules/bootstrap/scss/functions";

// Default variable overrides
// $body-bg: #e6efff;
:root {
  --font-size-base: 14px;
  --bs-card-border-color: "#000000" !important; //card border color
  --bodyColor: "#000000";
}
$font-size-base: var(--font-size-base);

// Required
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Optional Bootstrap components here
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";

// Congrats
@import "./utilities/congrats/congrats.scss";

@font-face {
  font-family: "Muli";
  src: local("Muli"), url(./assets/fonts/Muli-Regular.ttf) format("truetype");
}

body {
  overflow-x: hidden !important;
  font-family: Muli;
  // background: linear-gradient(90deg, rgb(234, 232, 232) 0%, rgb(222, 220, 220) 100%) !important;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.page-main-container {
  .sidebar-container {
    position: relative;
  }
}

.mw-100 {
  max-width: 100%;
}

.mh-100 {
  max-height: 100%;
}
.large-screen-width {
  width: 1440px;
}

@media (max-width: 1440px) {
  .large-screen-width {
    width: 100%;
  }
}

.emoji-picker-dark-mode {
  .EmojiPickerReact,
  .epr-emoji-category-label {
    background-color: unset !important;
  }
  .epr-search-container > input {
    background-color: transparent !important;
  }
}

.custom-card {
  box-shadow: none !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c6c6c6;
  border-radius: 10px;
}

.font-x-small {
  font-size: 10px;
}
.font-small {
  font-size: 12px;
}
.font-medium {
  font-size: 14px;
}
.font-large {
  font-size: 16px;
}
.font-x-large {
  font-size: 18px;
}
