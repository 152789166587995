.accordion-item {
	background: $card-bg;
	.accordion-button {
		background: $card-bg;
		color: $body-color;
	}
    .accordion-body{
        p{
            color: $body-color;
        }
    }
}